import { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { RouteObject } from 'react-router-dom';
import routeList from './routeList';
interface RouteObjectUpdated extends RouteObject {
  breadcrumb?: string;
}

const RenderRouter: FC<RouteObjectUpdated> = () => useRoutes(routeList);

export default RenderRouter;
