import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import apiHelper from '~/utils/apiHelper';
import { Moment } from 'moment';
import { store } from '../store';
import { Some } from '@utils/Some';
import { isObjectEmpty } from '@utils/isObjectEmpty';

type RootState = any;
type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export enum TABS {
  PERSONAL_INFO = 'personalInfo',
  CONTACT = 'contact',
  EDUCATION = 'education',
  EXPERIENCE = 'experience',
  SKILLS = 'skills',
}

export const resumeTabs = {};

export type AppState = {
  resume: {
    activeTab: number;
    resumeFormData: {
      personalInfo: {
        first_name: string;
        last_name: string;
        professional_title: string;
        about?: string;
        avatar_url: string;
      };
      contact: { email?: string; phone?: string };
      social: { data: [{ url: string }] };
      address: {
        address_line_1?: string;
        address_line_2?: string;
        country?: string;
        city?: string;
        state?: string;
        zip?: string;
      };
      education: {
        data: [
          {
            qualification: string;
            organization: string;
            address: {
              country?: string;
              city?: string;
              state?: string;
              zip?: string;
            };
            from: string | Moment;
            to: string | Moment;
            present?: boolean;
            website: string;
            merit?: {
              org: {
                logoUrl?: string;
                name?: string;
                id?: string;
              };
              id: string;
              title: string;
            };
          }
        ];
      };
      experience: {
        data: [
          {
            job_title?: string;
            employer?: string;
            address: {
              country?: string;
              city?: string;
              state?: string;
              zip?: string;
            };
            description?: string;
            present?: boolean;
            from: string | Moment;
            to: string | Moment;
            merit?: {
              org: {
                logoUrl?: string;
                name?: string;
                id?: string;
              };
              id: string;
              title: string;
            };
          }
        ];
      };
      language: {
        monther_tongue: string;
        monther_tongue_rating: number;
        data: [{ other_language: string; other_language_rating: number }];
      };
      skills: {
        data: [
          {
            skill: string;
            skill_rating: number;
            merit?: {
              org: {
                logoUrl?: string;
                name?: string;
                id?: string;
              };
              id: string;
              title: string;
            };
          }
        ];
      };
    };
    memberMerits: Array<{
      org: { logoUrl?: string; name?: string; id?: string };
      title: string;
      merit?: boolean;
      meritTemplateId?: string;
      id: string;
    }>;
    verificationMerits: Array<{
      id?: string;
      from?: string;
      fields: Array<{
        fieldId?: string;
        fieldName?: string;
        checked?: boolean;
      }>;
    }>;
    verificationMeritsToken: string;
  };
};

const RESUME_STATE = 'resume';
export const initialStateResume = {
  activeTab: 1,
  resumeFormData: {
    personalInfo: {
      first_name: '',
      last_name: '',
      professional_title: '',
      avatar_url: '',
    },
    contact: undefined,
    social: { data: [{ url: '' }] },
    address: {},
    education: { data: [{ from: null, to: null, website: '' }] },
    experience: { data: [{ from: null, to: null }] },
    language: { data: [{}] },
    skills: { data: [{}] },
  },
  memberMerits: [],
  verificationMerits: [],
  verificationMeritsToken: '',
};
const slice = createSlice({
  name: RESUME_STATE,
  initialState: initialStateResume,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    nextActiveTab: (state, action) => {
      state.activeTab = state.activeTab + action.payload;
    },
    setResumeFormData: (state, action) => {
      const newValue = {
        ...state.resumeFormData,
        [action.payload.from]: action.payload.values,
      };

      state.verificationMerits =
        action.payload.verificationMerits || state.verificationMerits;

      stateToDb(
        newValue,
        action.payload.verificationMerits || state.verificationMerits,
        action.payload.memberId,
        state.verificationMeritsToken
      );

      state.resumeFormData = newValue;
    },
    replaceResumeFormData: (state, action) => {
      if (action.payload) {
        state.resumeFormData = action.payload;
      }
    },
    setMerits: (state, { payload }: PayloadAction<any>) => {
      state.memberMerits = payload;
    },
    setVerificationMerits: (state, { payload }: PayloadAction<any>) => {
      state.verificationMerits = payload.merits;

      stateToDb(
        state.resumeFormData,
        payload.merits,
        payload.memberId,
        state.verificationMeritsToken
      );
    },
    replaceVerificationMerits: (state, action) => {
      if (action.payload) {
        state.verificationMerits = action.payload;
      }
    },
    setVerificationMeritsToken: (state, { payload }: PayloadAction<any>) => {
      state.verificationMeritsToken = payload;
    },
  },
});

const stateToDb = async (
  resumeData: any,
  verificationMerits: any,
  memberId: string,
  verificationToken?: string
) => {
  try {
    if (memberId) {
      await apiHelper(
        `/resume/data/${memberId}`,
        {
          resumeData,
          token: verificationToken,
          verificationMerits: { merits: verificationMerits },
        },
        {
          method: 'POST',
        }
      );
    }
  } catch (error) {}
};

export const loadResumeFormData = (
  memberId: string
): AppThunk => async dispatch => {
  try {
    const resumeData = await apiHelper(`/resume/data/${memberId}`);

    if (!isObjectEmpty(resumeData) && resumeData?.['personalInfo']) {
      dispatch(replaceResumeFormData(resumeData));
    }

    await fetchAndSetVerificationMeritsToken(memberId);
  } catch (error) {}
};

export const deleteResumeData = (
  memberId: string
): AppThunk => async dispatch => {
  try {
    await apiHelper(`/resume/data/${memberId}`, {}, { method: 'DELETE' });
    dispatch(replaceResumeFormData(initialStateResume.resumeFormData));
    dispatch(replaceVerificationMerits(initialStateResume.verificationMerits));
    dispatch(
      setVerificationMeritsToken(initialStateResume.verificationMeritsToken)
    );

    await fetchAndSetVerificationMeritsToken(memberId);
  } catch (error) {}
};

const fetchAndSetVerificationMeritsToken = async (memberId?: string) => {
  const verifiedMeritToken = await apiHelper(`/resume/token/${memberId}`);

  if (
    verifiedMeritToken &&
    !isObjectEmpty(verifiedMeritToken) &&
    verifiedMeritToken?.['token']
  ) {
    store.dispatch(setVerificationMeritsToken(verifiedMeritToken?.['token']));

    if (verifiedMeritToken['data']['merits']) {
      store.dispatch(
        replaceVerificationMerits(verifiedMeritToken?.['data']['merits'])
      );
    }
  }
};

export const getMemberMerits = (type: string): AppThunk => async dispatch => {
  try {
    const memberId = store.getState().auth.currentUser.memberId;

    const data = await apiHelper(`/members/merits/${memberId}`);

    dispatch(setUsedMemberMerits(type, data));
  } catch (error) {}
};

export const getMemberMeritFields = async (
  meritTemplateId?: string,
  orgId?: string
): Promise<AppState['resume']['verificationMerits'][0]['fields']> => {
  const data = await apiHelper(`/members/fields/${meritTemplateId}/${orgId}`);
  return data;
};

export const verifiedMeritRemove = async ({
  type,
  index,
  save,
}: {
  type: 'experience' | 'education' | 'skills';
  index: number;
  save?: boolean;
}) => {
  let newVerifiedMerit: any[] = [];
  const verificationMerits = await store.getState().resume.verificationMerits;
  const resumeFormData = await store.getState().resume.resumeFormData;

  if (verificationMerits) {
    newVerifiedMerit = [...verificationMerits];

    // eslint-disable-next-line
    const nIndex = newVerifiedMerit.findIndex(item => {
      if (!resumeFormData[type].data[index]) {
        return false;
      }
      if (
        // @ts-ignore
        item.id === resumeFormData[type].data[index]['merit']?.id &&
        item.from === type
      )
        return true;
    });
    if (nIndex >= 0) {
      newVerifiedMerit.splice(nIndex, 1);
    }
  }

  if (save) {
    await store.dispatch(replaceVerificationMerits(newVerifiedMerit));
  }

  return newVerifiedMerit;
};

export const setUsedMemberMerits = (
  type: string,
  data?: any
): AppThunk => async dispatch => {
  try {
    const resumeFormData = store.getState().resume.resumeFormData;
    const memberMerits = data ? data : store.getState().resume.memberMerits;

    // @ts-ignore
    // eslint-disable-next-line
    const selectedValues = resumeFormData[type]['data'].map((item: any) => {
      if (Some(item['merit'])) {
        return item['merit']['title'];
      }
    });

    const merits = memberMerits.map((item: any) => {
      return { ...item, merit: selectedValues.indexOf(item['title']) !== -1 };
    });

    // const newValues = update(resumeFormData[type].data, {
    //   [index]: {
    //     merit: { $set: item },
    //   },
    // });

    dispatch(setMerits(merits));
  } catch (error) {}
};

export default slice;

export const {
  setActiveTab,
  nextActiveTab,
  setResumeFormData,
  replaceResumeFormData,
  setVerificationMerits,
  replaceVerificationMerits,
  setVerificationMeritsToken,
  setMerits,
} = slice.actions;

export const resumeSelector = (state: AppState) => state.resume;
