import { createSlice, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import apiHelper from '~/utils/apiHelper';
import { Some } from '@utils/Some';
import { isObjectEmpty } from '@utils/isObjectEmpty';

type RootState = any;
type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export interface PathwayItem {
  meritTemplateId: string;
  title: string;
  shortDescription: string;
  description: string;
  condition: string;
  completed?: string;
  completedDate?: string;
}

export interface Pathway {
  id?: number;
  orgId: string;
  org: {
    orgLogo: string;
    orgTitle: string;
  },
  title: string;
  description: string;
  data: Array<Array<PathwayItem>>;
  isCompleted?: boolean;
}

export type AppState = {
  pathways: {
    total: number;
    pathwaysList: Array<Pathway>;
    pathwaysListOrg: [
      {
        id: number;
        orgId: string;
        org: {
          orgLogo: string;
          orgTitle: string;
        },
        title: string;
        description: string;
      }
    ];
  };
};

const PATHWAYS_STATE = 'pathways';
export const initialStatePathways = {
  total: 0,
  pathwaysList: [],
  pathwaysListOrg: [],
};

const slice = createSlice({
  name: PATHWAYS_STATE,
  initialState: initialStatePathways,
  reducers: {
    replacePathways: (state, action) => {
      if (action.payload) {
        state.pathwaysList = action.payload;
      }
    },
    replacePathwaysOrg: (state, action) => {
      if (action.payload) {
        state.pathwaysListOrg = action.payload;
      }
    },
  },
});

export const getMemberPathway = async (memberId: string, pathwayId: string) => {
  try {
    const pathwayData = await apiHelper(
      `/pathways/member/${memberId}/${pathwayId}`
    );

    // if (!isObjectEmpty(pathwayData) && pathwayData?.['data']) {
    //   dispatch(replacePathways(pathwayData['data']));
    // }
    return pathwayData;
  } catch (error) {}
};

export const searchPathways = (search: string): AppThunk => async dispatch => {
  try {
    const pathways = await apiHelper(`/pathways?search=${search}`);

    if (!isObjectEmpty(pathways) && pathways?.['data']) {
      dispatch(replacePathways(pathways['data']));
    }
  } catch (error) {}
};

export const getOrgPathways = (orgId?: string): AppThunk => async dispatch => {
  try {
    const pathways = await apiHelper(`/pathways/org/${orgId}`);

    if (Some(pathways)) {
      dispatch(replacePathwaysOrg(pathways));
    }
  } catch (error) {}
};

export const getOrgPathway = async (pathwayId: string) => {
  try {
    const pathwayData = await apiHelper(`/pathways/${pathwayId}`);

    return pathwayData;
  } catch (error) {}
};

export const updatePathway = async (data: Pathway) => {
  try {
    if (data) {
      await apiHelper(`/pathways/${data.id}`, data, {
        method: 'PUT',
      });
    }
  } catch (error) {}
};

export const newPathway = async (data: Pathway) => {
  try {
    if (data) {
      return await apiHelper(`/pathways`, data, {
        method: 'POST',
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const removePathway = async (data: Partial<Pathway>) => {
  try {
    if (data) {
      await apiHelper(
        `/pathways/${data.id}`,
        {},
        {
          method: 'DELETE',
        }
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const getOrgMeritTemplates = async (orgId?: string) => {
  try {
    const meritTemplates = await apiHelper(`/members/merittemplates/${orgId}`);

    return meritTemplates;
  } catch (error) {
    console.log(error);
  }
};

export const getOrgInfo = async (orgId?: string) => {
  try {
    const orgInfo = await apiHelper(`/members/org/${orgId}`);

    return orgInfo;
  } catch (error) {
    console.log(error);
  }
};

export default slice;

export const { replacePathways, replacePathwaysOrg } = slice.actions;

export const pathwaysSelector = (state: AppState) => state.pathways;
