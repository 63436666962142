import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import globalModule from './modules/global';
import resumeModule from './modules/resume';
import pathwaysModule from './modules/pathways';
import authModule from './modules/auth';

const reducer = combineReducers({
  global: globalModule.reducer,
  resume: resumeModule.reducer,
  pathways: pathwaysModule.reducer,
  auth: authModule.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: [''],
  // blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    // serializableCheck: {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
  }),
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof reducer>;
