import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default async (url = '/', data?: any, opts?: any) => {
  try {
    if (data) opts.body = JSON.stringify(data);
    const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      ...opts,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
        Authorization: cookies.get('token'),
      },
    });
    const json = await res.json();
    return json;
  } catch (e) {
    console.error('Fetch error', e);
    return e;
  }
};
