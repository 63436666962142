import React, { lazy, Suspense } from 'react';
import LoadingPage from '~/pages/loading';

const LayoutPage = React.lazy(() => import('~/layouts/MainLayout'));
const PrivateRouteWrapper = React.lazy(() => import('./PrivateRouteWrapper'));
const PrivateAdminRouteWrapper = React.lazy(
  () => import('./PrivateAdminRouteWrapper')
);
const PublicRouteWrapper = React.lazy(() => import('./PublicRouteWrapper'));

const DashboardPage = React.lazy(() => import('~/pages/DashboardPage'));
const LoginPage = React.lazy(() => import('~/pages/LoginPage'));

/** Resume App **/
const ResumeLayoutPage = React.lazy(
  () => import('@apps/resume/layouts/MainLayout')
);
const ResumePage = React.lazy(() => import('@apps/resume/pages/ResumePage'));
const ValidationPage = React.lazy(
  () => import('@apps/resume/pages/ValidationPage')
);

/** Pathways App **/
const PathwaysLayoutPage = React.lazy(
  () => import('@apps/pathways/layouts/MainLayout')
);
const PathwaysPage = React.lazy(
  () => import('@apps/pathways/pages/PathwaysPage')
);
const PathwayPage = React.lazy(
  () => import('@apps/pathways/pages/PathwayPage')
);
const AdminPathwaysPage = React.lazy(
  () => import('@apps/pathways/pages/admin/PathwaysPage')
);
const AdminPathwayPage = React.lazy(
  () => import('@apps/pathways/pages/admin/PathwayPage')
);
const AdminPathwayEditPage = React.lazy(
  () => import('@apps/pathways/pages/admin/PathwayEditPage')
);

const NotFound = lazy(() => import('~/pages/404'));

const routeList = [
  {
    path: '',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <PublicRouteWrapper />,
      </Suspense>
    ),
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <LoginPage />
          </Suspense>
        ),
      },
      {
        path: 'login',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <LoginPage />
          </Suspense>
        ),
      },
      {
        path: 'success/:orgId',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <LoginPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <PrivateRouteWrapper />,
      </Suspense>
    ),
    children: [
      {
        path: 'dashboard',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <LayoutPage>
              <DashboardPage />
            </LayoutPage>
          </Suspense>
        ),
      },
      {
        path: 'resume',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <ResumeLayoutPage>
              <ResumePage />
            </ResumeLayoutPage>
          </Suspense>
        ),
        breadcrumb: 'Resume Builder',
        member: 'resume',
      },
      {
        path: 'pathways',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <PathwaysLayoutPage>
              <PathwaysPage />
            </PathwaysLayoutPage>
          </Suspense>
        ),
        breadcrumb: 'Pathways',
        member: 'pathways',
      },
      {
        path: 'pathways/:pathwayId',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <PathwaysLayoutPage>
              <PathwayPage />
            </PathwaysLayoutPage>
          </Suspense>
        ),
        breadcrumb: ':pathwayId',
        member: 'pathways',
      },
      {
        path: '*',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <LayoutPage>
              <NotFound />
            </LayoutPage>
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <PrivateAdminRouteWrapper />,
      </Suspense>
    ),
    children: [
      {
        path: 'manage-pathways',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <PathwaysLayoutPage>
              <AdminPathwaysPage />
            </PathwaysLayoutPage>
          </Suspense>
        ),
        breadcrumb: 'Manage Pathway',
        member: 'pathways',
      },
      {
        path: 'manage-pathways/:pathwayId',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <PathwaysLayoutPage>
              <AdminPathwayPage />
            </PathwaysLayoutPage>
          </Suspense>
        ),
        breadcrumb: ':pathwayId',
        member: 'pathways',
      },
      {
        path: 'manage-pathways/:pathwayId/edit',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <PathwaysLayoutPage>
              <AdminPathwayEditPage />
            </PathwaysLayoutPage>
          </Suspense>
        ),
        breadcrumb: 'Edit Pathway',
        member: 'pathways',
      },
      {
        path: 'manage-pathways/new',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <PathwaysLayoutPage>
              <AdminPathwayEditPage />
            </PathwaysLayoutPage>
          </Suspense>
        ),
        breadcrumb: 'New Pathway',
        member: 'pathways',
      },
    ],
  },
  {
    path: 'resume/:validationId',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ValidationPage />
      </Suspense>
    ),
  },
];

export default routeList;
