import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import apiHelper from '~/utils/apiHelper';
import { useNavigate } from 'react-router-dom';
import {
  replaceResumeFormData,
  replaceVerificationMerits,
  setVerificationMeritsToken,
  setMerits,
  initialStateResume,
} from '@redux/modules/resume';

export type RootState = any;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

const auth = 'auth';
export interface AuthError {
  message: string;
}

export interface CurrentUser {
  display_name: string;
  firstName: string;
  lastName: string;
  memberId: string;
  orgId?: string;
  token?: string;
  photo_url?: string;
}

export interface CurrentAdminUser {
  display_name: string;
  orgId: string;
  orgLogo: string;
  orgTitle: string;
  token: string;
  admin: boolean;
}

export interface AuthState {
  isAuth: boolean;
  currentUser: CurrentUser;
  currentAdmin: CurrentAdminUser;
  isLoading: boolean;
  error: AuthError;
}

export const initialState: AuthState = {
  isAuth: false,
  isLoading: false,
  currentUser: {
    display_name: '',
    firstName: '',
    lastName: '',
    memberId: '',
    orgId: undefined,
    token: undefined,
    photo_url: undefined,
  },
  currentAdmin: {
    display_name: '',
    orgId: '',
    orgLogo: '',
    orgTitle: '',
    token: '',
    admin: false,
  },
  error: { message: '' },
};

const slice = createSlice({
  name: auth,
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setAuthSuccess: (state, { payload }: PayloadAction<any>) => {
      state.currentUser = payload;
      state.isAuth = true;
      state.error = { message: '' };
    },
    setAuthAdminSuccess: (state, { payload }: PayloadAction<any>) => {
      state.currentAdmin = payload;
      state.isAuth = true;
      state.error = { message: '' };
    },
    setLogOut: state => {
      state.isAuth = false;
      state.currentUser = {
        display_name: '',
        firstName: '',
        lastName: '',
        memberId: '',
        orgId: undefined,
        token: undefined,
        photo_url: undefined,
      };
      state.error = { message: '' };
    },
    setAuthFailed: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isAuth = false;
    },
  },
});

export const {
  setAuthSuccess,
  setLogOut,
  setLoading,
  setAuthFailed,
  setAuthAdminSuccess,
} = slice.actions;

export const login = (values: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true));
    const navigate = useNavigate();

    const memberData = await apiHelper(`/members${values.url}`);

    if (memberData.auth === 'success') {
      await dispatch(
        setAuthSuccess({
          display_name: `${memberData.firstName} ${memberData.lastName}`,
          firstName: memberData.firstName,
          lastName: memberData.lastName,
          memberId: memberData.memberId,
          orgId: memberData.orgId,
          token: memberData.token,
          photo_url: undefined,
        })
      );

      navigate('/dashboard');
    } else {
      dispatch(setAuthFailed({ message: 'Auth Failed!' }));
    }
  } catch (error) {
    dispatch(setAuthFailed(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const link = (values: any): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true));
    const navigate = useNavigate();

    const orgData = await apiHelper(`/members${values.url}`);

    if (orgData.auth === 'success') {
      await dispatch(
        setAuthAdminSuccess({
          display_name: orgData.title,
          orgId: orgData.id,
          orgLogo: orgData.logoUrl,
          orgTitle: orgData.title,
          token: orgData.token,
          admin: orgData.admin,
        })
      );

      navigate('/manage-pathways');
    } else {
      dispatch(setAuthFailed({ message: 'Auth Failed!' }));
    }
  } catch (error) {
    dispatch(setAuthFailed(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getLoginLink = (): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true));

    const data = await apiHelper(`/members/request-login-with-merit-url`);

    if (data.request_loginwithmerit_url) {
      window.location.replace(data.request_loginwithmerit_url);
    } else {
      dispatch(setAuthFailed({ message: 'Auth Failed!' }));
    }
  } catch (error) {
    dispatch(setAuthFailed(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getLinkAppLink = (): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true));

    const data = await apiHelper(`/members/request_linkapp_url`);

    if (data.request_linkapp_url) {
      window.location.replace(data.request_linkapp_url);
    } else {
      dispatch(setAuthFailed({ message: 'Auth Failed!' }));
    }
  } catch (error) {
    dispatch(setAuthFailed(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const logOut = (): AppThunk => async dispatch => {
  try {
    dispatch(setLoading(true));
    // await apiCallendUserSession();
    dispatch(setLogOut());

    dispatch(replaceResumeFormData(initialStateResume.resumeFormData));

    dispatch(replaceVerificationMerits(initialStateResume.verificationMerits));

    dispatch(setMerits(initialStateResume.memberMerits));

    dispatch(
      setVerificationMeritsToken(initialStateResume.verificationMeritsToken)
    );
  } catch (error) {
    dispatch(setAuthFailed(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export default slice;
export const authSelector = (state: { auth: AuthState }) => state.auth;
