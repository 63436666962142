import { Spin } from 'antd';
import React from 'react';

const LoadingPage: React.FC<{}> = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin size="large" tip="Loading..."></Spin>
    </div>
  );
};

export default LoadingPage;
